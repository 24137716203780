//src/app/filter/FilterComponent.js
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Drawer from './Drawer'
import { Button, makeStyles, Typography, IconButton, Menu, MenuItem, Grid, TextField } from '@material-ui/core'
import HistoryIcon from "@material-ui/icons/History"
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

const FilterComponent = ({ open, onOpenFilter, filters, totalCollections, onGetFilterState, selected, onApplyFilter, onRefresh, onSortBy, collections, onAddAllToCart, onEmptyCart }) => {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [menuName, setMenuName] = React.useState("Sort By");
    const [search, setSearch] = React.useState(null);
    const [selectAll, setSelectAll] = React.useState(false);


    const toggleSelect = () => {

      if(selectAll === false){

        //let list = collections;
        let list = JSON.parse(sessionStorage.getItem('visibleCollections')); //check collection/CollectionContainer.js
        let selections = list.map((item, key) => {
          return item.id;
        });

        onAddAllToCart(selections, list);
        setSelectAll(true);

      } else {

        onEmptyCart();
        setSelectAll(false);

      }

    }

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = (direction, name) => {
        setMenuName(name)
        if (direction)
            onSortBy(direction)
        setAnchorEl(null);
    }

    const handleRefresh = () => {
        onRefresh()
        setMenuName("Sort By")
        setSearch(null)
    }

    useEffect(() => {

      if(search && search.length > 1){
        onGetFilterState('search', search.toLowerCase());
        onApplyFilter();
      }

      if(search != null && search.length <= 1){
        onRefresh();
      }


    },[search]);

    if (!filters) {
        return <Typography variant="body2" align="center">Loading...</Typography>
    }
    return (
        <div className={classes.root}>
            <div className={classes.wrapFilterHeader}>
                <Typography variant="h6">Collections ({totalCollections}) <IconButton onClick={handleRefresh}><HistoryIcon /></IconButton></Typography>

                <div>
                    <Grid container spacing={1}>
                        <Grid item md={12}>

                            <TextField
                                label=""
                                name="search"
                                variant="outlined"
                                className={classes.inputText}
                                style={{marginRight: 10, minWidth: 200}}
                                autoComplete="no"
                                placeholder='Search...'
                                onChange={(e) => setSearch(e.target.value)}
                                value={search || ""}
                            />


                            <Button
                              style={{ marginRight: 10 }}
                              color="primary"
                              variant="contained"
                              className="btn-default btn-primary"
                              onClick={() => toggleSelect()}>
                              {selectAll ? "Reset Selection" : "Select All"}
                            </Button>

                            <Button style={{ marginRight: 10 }} color="primary" variant="contained" className="btn-default btn-secondary" onClick={() => onOpenFilter(true)}>Filter</Button>


                            <Button
                                aria-controls="sortby-menu"
                                aria-haspopup="true"
                                onClick={handleClick}
                                color="primary"
                                variant="contained"
                                style={{ whiteSpace: "nowrap" }}
                                className="btn-default btn-primary">{menuName}&nbsp;<ArrowDropDownIcon /></Button>

                            <Menu
                                id="sortby-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={() => handleClose(null, "Sort By")}
                                style={{ backgroundColor: "rgba(0,0,0,0.2)" }}
                            >
                                <MenuItem onClick={() => handleClose("ASC", "Name A to Z")}>Name A to Z</MenuItem>
                                <MenuItem onClick={() => handleClose("DESC", "Name Z to A")}>Name Z to A</MenuItem>
                                <MenuItem onClick={() => handleClose("PRICE_ASC", "Price High to Low")}>Price High to Low</MenuItem>
                                <MenuItem onClick={() => handleClose("PRICE_DESC", "Price Low to High")}>Price Low to High</MenuItem>

                            </Menu>
                        </Grid>
                    </Grid>
                </div>


            </div>

            <Drawer
                open={open}
                onHandleDrawerOpen={onOpenFilter}
                filters={filters}
                onGetFilterState={onGetFilterState}
                onApplyFilter={onApplyFilter}
                onRefresh={onRefresh}
                selected={selected} />
        </div>
    )
}


const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: 24
    },
    inputText: {
      '& fieldset': {
        borderRadius: 50,
        border: '1px solid black',
      },
      '& input':{
        padding: '17px 14px'
      }
    },
    wrapFilterHeader: {
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            display: "inline-block",
            justifyContent: "space-around"
        }
    }
}))

FilterComponent.propTypes = {
    onOpenFilter: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
}

export default FilterComponent
