import React from 'react'
import { Grid, Typography, Hidden, Divider } from '@material-ui/core'


const FactComponent = ({ fact }) => {

    return (
      <Grid item xs={12}>
          <Typography variant="h6" style={{ whiteSpace: "nowrap" }}>{fact.title}</Typography>
          <Typography variant="h2">{fact.number} <small>{fact.unit}</small></Typography>
          <Hidden only={["xs"]}>
              <Grid item xs={12} style={{'paddingTop': '10px'}}>
                  <Divider style={{ maxWidth: 337 }} />
              </Grid>
          </Hidden>
      </Grid>
    )
}

export default FactComponent;
