//src/app/common/thumbnail.js
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { makeStyles, Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
// import CardMedia from '@material-ui/core/CardMedia';
import { red } from '@material-ui/core/colors';
import { Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { DEFAULT_IMG } from '../../constant'
//import Img from './img'

import Lightbox from "lightbox-react";
import ImageCaption from './imageCaption';

const useStyles = makeStyles(theme => ({
    card: {
        maxWidth: "100%",
        boxShadow: "none",
        borderRadius: 0
    },
    add: {
        marginLeft: 'auto',
    },
    avatar: {
        backgroundColor: red[500],
    },
    media: {
        height: 'auto',
        width: '100%',
        margin: 'auto'
    }
}))

const Thumbnail = ({ item, collectionId, productId, clickAction, showCartBtn, isSelected, onRemoveFromCart, token, isClient }) => {
    const classes = useStyles();


    const [photoIndex, setPhotoIndex ] = useState(0);
    const [preview, setPreview ] = useState(false);

    var linkTo;

    const handlePreview = (index) => {
        setPhotoIndex(index);
        setPreview(true);
    }



    if(productId){

      //product
      linkTo = "/collections/"+collectionId;
      var imageTitle = item.name;
      var imageCaption = <ImageCaption product={item} linkTo={linkTo} />


      return (

          <Card className={classes.card} data-anchor="test">

              <div onClick={() => handlePreview(0)}
                  style={{
                      cursor: 'pointer',
                      textDecoration: "none",
                      width: "100%",
                  }}>
                  { /*
                    <Img
                      image={item.images && item.images[0] ? item.images[0].path : DEFAULT_IMG}
                  /> */ }

                  <img
                    loading="lazy"
                    alt="woodnet"
                    height="427"
                    className={classes.media}
                    src={item.images && item.images[0] ? item.images[0].path : DEFAULT_IMG} />

              </div>


              {preview && (
                  <Lightbox
                    mainSrc={item.images[photoIndex].path}
                    nextSrc={item.images[(photoIndex + 1) % item.images.length]}
                    prevSrc={item.images[(photoIndex + item.images.length - 1) % item.images.length]}
                    imageTitle={imageTitle}
                    imageCaption={imageCaption}
                    imagePadding={10}
                    onCloseRequest={() => setPreview(false)}
                    onMovePrevRequest={() => setPhotoIndex((photoIndex + item.images.length - 1) % item.images.length) }
                    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % item.images.length)}
                  />
              )}

              <div style={{ display: "flex", justifyContent: "space-between", height: 50, alignItems: 'center' }}>
                  <Typography variant="body1">{item.name}</Typography>
                  {!isClient && showCartBtn ? <Button
                      onClick={() => {
                          if (isSelected) {
                              onRemoveFromCart(item)
                          } else {
                              clickAction(item)
                          }
                      }}
                      size="small"
                      style={{
                          backgroundColor: isSelected ? "black" : "white",
                          color: isSelected ? "white" : "black",
                          borderRadius: 27,
                          fontWeight: 600,
                          borderColor: "black"
                      }}
                      variant="outlined">
                      {
                          !isClient && isSelected ? <RemoveIcon style={{ fontSize: 14, fontWeight: 600 }} />
                              : <AddIcon style={{ fontSize: 14, fontWeight: 600 }} />
                      }
                      {isSelected ? "Remove from cart" : "Add to cart"}

                  </Button> : null}
              </div>

          </Card>

      );

    } else {

      //collection
      linkTo = "/collections/"+collectionId;

      return(
        <Card className={classes.card} data-anchor="test">

          <Link to={linkTo}
              style={{
                  textDecoration: "none",
                  width: "100%",
              }}>
              {/*<Img
                  image={item.images && item.images[0] ? item.images[0].path : DEFAULT_IMG}
              /> */}

              <img
                loading="lazy"
                alt="woodnet"
                className={classes.media}
                height="427"
                src={item.images && item.images[0] ? item.images[0].path : DEFAULT_IMG} />
          </Link>

          <div style={{ display: "flex", justifyContent: "space-between", height: 50, alignItems: 'center' }}>
              <Typography variant="body1">{item.name}</Typography>
              {!isClient && showCartBtn ? <Button
                  onClick={() => {
                      if (isSelected) {
                          onRemoveFromCart(item)
                      } else {
                          clickAction(item)
                      }
                  }}
                  size="small"
                  style={{
                      backgroundColor: isSelected ? "black" : "white",
                      color: isSelected ? "white" : "black",
                      borderRadius: 27,
                      fontWeight: 600,
                      borderColor: "black"
                  }}
                  variant="outlined">
                  {
                      !isClient && isSelected ? <RemoveIcon style={{ fontSize: 14, fontWeight: 600 }} />
                          : <AddIcon style={{ fontSize: 14, fontWeight: 600 }} />
                  }
                  {isSelected ? "Remove from cart" : "Add to cart"}

              </Button> : null}
          </div>
      </Card>);

    }


}

export default Thumbnail;

Thumbnail.propTypes = {
    item: PropTypes.object.isRequired
}
