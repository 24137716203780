import { combineReducers } from 'redux'
import collections from '../app/collection/collectionsReducer'
import products from '../app/product/productsReducer'
import finishings from '../app/finishing/finishingsReducer'

import notifications from './notification/notificationReducer'
import layout from '../app/layout/layoutReducer'
import library from './library/libraryReducer'
import filter from '../app/filter/filterReducer'
import productFilter from '../app/productFilter/filterReducer'

import auth from '../app/auth/authReducer'
import cart from '../app/cart/cartReducer'



const appReducer = combineReducers({
    library,
    collections,
    products,
    finishings,
    notifications,
    filter,
    productFilter,
    layout,
    auth,
    cart
})

const fnDefault = (state, action) => {
    // let newState = state
    if (action.type === 'LOGOUT_AUTH') {
        state = undefined
    }

    return appReducer(state, action)
}

export default fnDefault;
