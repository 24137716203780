import React from 'react'
import { connect } from 'react-redux'
import Collections from '../collection/actions'
import AccessoryComponent from './AccessoryComponent'
import { addToCart, removeFromCart } from '../cart/actions'
import queryString from 'query-string';

// Check if item in current cart exist
const isSelected = (selections, id) => {
    return selections.includes(id)
}

class AccessoryContainer extends React.Component {

    state = {
        id: null,
    }

    componentDidMount() {
        let id = this.props.match.params.id
        this.setState({ id: id })
        this.props.onViewCollection(id)

        let search = queryString.parse(this.props.location.search);
        if(search.section === 'products'){
          // scrolldown to products
          // TO DO
          console.log('SCROLLING TO PRODUCTS');

        }

    }

    componentWillUnmount() {
        this.props.onGetCollection(null)
    }

    render() {
        if (this.props.isFetching) {
            return <p style={{ marginTop: "10%", textAlign: "center" }}>Loading....</p>
        }

        return (
            <AccessoryComponent
                item={this.props.item}
                onAddToCart={this.props.onAddToCart}
                isSelected={isSelected(this.props.selections, this.props.item.id)}
                onRemoveFromCart={this.props.onRemoveFromCart}
                token={this.props.token}
                isClient={this.props.isClient}
            />
        )
    }
}

const mapStateToProps = state => {

    return {
        item: state.collections.item || {},
        isFetching: state.collections.isFetching,
        selections: state.cart.selections,
        token: state.auth.token,
        isClient: state.auth.user.is_client
    }
}

const mapDispatchToProps = dispatch => {
    const onViewCollection = (id) => dispatch(Collections.viewCollection(id));
    const onGetCollection = (item) => dispatch(Collections.getCollection(item))
    const onAddToCart = (item) => dispatch(addToCart(item))
    const onRemoveFromCart = (item) => dispatch(removeFromCart(item))

    return {
        onViewCollection,
        onAddToCart,
        onGetCollection,
        onRemoveFromCart
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccessoryContainer)
