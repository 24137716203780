//src/app/auth/LoginContainer.js
import React from 'react'
import { connect } from 'react-redux'
import { onChangeCredential, getAuthenticated } from './actions'
import LoginComponent from './LoginComponent'

class LoginContainer extends React.Component {

    state = {
        height: window.innerHeight
    }

    componentDidMount() {
        window.addEventListener("resize", this.changeHeight)
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.changeHeight);
    }

    changeHeight = () => {
        this.setState({
            height: window.innerHeight
        })
    }

    handleAuthentication = (e) => {
        e.preventDefault();
        this.props.getAuthenticated()
    }

    render() {


        return (

            <LoginComponent
                auth={this.props.auth}
                theme={this.props.theme}
                onChangeCredential={this.props.onChangeCredential}
                onSubmitLogin={this.handleAuthentication}
                height={this.state.height}
                message={this.props.message}
                toekn={this.props.token}
                isFetching={this.props.isFetching}
            />
        )
    }
}

const mapStateToProps = state => {

    return {
        auth: state.auth.item || {},
        theme: state.library.company_theme.payload || {},
        isFetching: state.auth.isFetching,
        message: state.auth.message,
        token: state.auth.token
    }
}


export default connect(mapStateToProps, { onChangeCredential, getAuthenticated })(LoginContainer)