//src/app/home/actions.js
import axios from 'axios'
import { END_POINT } from '../../constant'
import { setNotification } from '../../redux/notification/actions'

export const SAVED_INITIAL_PRODUCT_LIST = 'SAVED_INITIAL_PRODUCT_LIST'

export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS'
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS'
export const GET_PRODUCT = 'GET_PRODUCT'
export const FAILED_PRODUCTS = 'FAILED_PRODUCTS'

const requestproducts = () => {
    return {
        type: REQUEST_PRODUCTS,
    }
}

const getProducts = () => {

    return async (dispatch, getState) => {
        // Loading start
        dispatch(requestproducts())

        try {
            const token = getState().auth.token
            const request = await axios.get(`${END_POINT}products?token=${token}`);
            const data = request.data;

            dispatch(receiveProducts(data))
            dispatch(saveProducts(data))
        } catch (err) {
            dispatch(setNotification(err.error, "error"))
            dispatch(failedProducts())
        }
    }
}


const receiveProducts = products => ({
    type: RECEIVE_PRODUCTS,
    products
})

const saveProducts = products => ({
    type: SAVED_INITIAL_PRODUCT_LIST,
    products
})

const failedProducts = () => ({
    type: FAILED_PRODUCTS,
})

const fnDefault = {
    getProducts,
    receiveProducts,
    saveProducts
}

export default fnDefault;
