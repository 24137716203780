//src/app/layout/actions.js
import axios from 'axios'
import { END_POINT } from '../../constant'
import Products from '../product/actions'
import { setNotification } from '../../redux/notification/actions'

const OPEN_FILTER = 'OPEN_FILTER'
const GET_FILTERS = 'GET_FILTERS'
const GET_FILTER_STATE = 'GET_FILTER_STATE'
const RESET_SELECTION = 'RESET_SELECTION'


const openFilter = (bool = false) => {
    return {
        type: OPEN_FILTER,
        bool
    }
}


const getFilters = () => {
    return async (dispatch, getState) => {
        try {
            const token = getState().auth.token
            const request = await axios.get(`${END_POINT}filters?token=${token}`);
            const data = request.data

            dispatch(setFilters(data))
        } catch (err) {
            dispatch(setNotification("Server error, please try again", "error"));
        }
    }
}

const setFilters = (data) => {
    return {
        type: GET_FILTERS,
        payload: data
    }
}


const getFilterState = (fieldName, value) => {
    return {
        type: GET_FILTER_STATE,
        fieldName,
        value
    }
}

const filterArray = (array, filters) => {
    const filterKeys = Object.keys(filters);
    return array.filter(item => {
        // validates all filter criteria
        return filterKeys.every(key => {
            // If its an array of object
            if (key === "category_id") {
              if (item.category_id === filters[key]) {
                  return item;
              }
            }
            return null
        });
    })
}

const applyFilter = () => {
    // do stuff here and update
    return (dispatch, getState) => {
        const products = getState().products.unFilteredList
        const filterKeys = getState().filter.selected;
        dispatch(openFilter(false))

        if (Object.keys(filterKeys).length === 0) {
            dispatch(setNotification("No filter has been selected", "error"));
        } else {
            const newProducts = filterArray(products, filterKeys);
            dispatch(Products.receiveProducts(newProducts));

            console.log(newProducts)

            if (!newProducts || newProducts.length === 0) {
                dispatch(setNotification("No product found", "error"));
                dispatch(openFilter(false))
            } else {
                // close filter-box
                dispatch(openFilter(false))
            }
        }
    }
}


const sortBy = (direction) => {

    return (dispatch, getState) => {
        const products = getState().products.list
        console.log("PRODUCTS", products)

        let newProducts = []
        switch (direction) {
            case "ASC":
                newProducts = products.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                break;
            case "DESC":
                newProducts = products.sort((a, b) => (a.name < b.name) ? 1 : ((b.name < a.name) ? -1 : 0));
                break;
            default:
                break;
        }

        dispatch(Products.receiveProducts(newProducts));
    }
}



const resetResult = () => {
    return (dispatch, getState) => {
        const products = getState().products.unFilteredList
        dispatch(resetSelection())
        dispatch(Products.receiveProducts(products))

    }
}

const resetSelection = () => {
    return {
        type: RESET_SELECTION,
    }
}


export {
    openFilter,
    getFilters,
    getFilterState,
    applyFilter,
    resetResult,
    sortBy,

    OPEN_FILTER,
    GET_FILTERS,
    GET_FILTER_STATE,
    RESET_SELECTION
}
