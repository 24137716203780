//src/app/home/CollectionContainer.js
import React from 'react'
import { connect } from 'react-redux'
import Products from './actions'
import ProductComponent from './ProductComponent'
import FilterContainer from '../productFilter/FilterContainer'
import { applyFilter } from '../productFilter/actions'
import { Typography } from '@material-ui/core'

class ProductContainer extends React.Component {

    componentDidMount() {
        if (this.props.location.state) {
            this.filterData(this.props.location.state)
        }
    }


    filterData = (filter) => {
        this.props.onGetFilterState("type", filter)
        this.props.onApplyFilter()
    }

    render() {
        const { products, displayFilter, token, isClient } = this.props

        console.log('PRODUCTS', products);

        if (this.props.isFetching) {
            return <p style={{ marginTop: "10%", textAlign: "center" }}>Loading....</p>
        }

        if (!products) {
            return <Typography variant="body1" align="center">No products found !</Typography>
        }

        const data = products

        return (
            <div id="products">
                {displayFilter ? <FilterContainer totalProducts={data && data.length} /> : false}
                <ProductComponent
                    products={data}
                    token={token}
                    isClient={isClient} />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        products: state.products.list,
        isFetching: state.products.isFetching,
        token: state.auth.token,
        isClient: state.auth.user.is_client
    }
}

const mapDispatchToProps = dispatch => {
    const onGetProducts = () => dispatch(Products.getProducts());
//    const onGetFilterState = (fieldName, value) => dispatch(getFilterState(fieldName, value))
    const onApplyFilter = () => dispatch(applyFilter())

    return {
        onGetProducts,
        onApplyFilter
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer)
