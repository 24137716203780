//src/redux/library/baseReducer.js

import {
    GET_INIT_DATA,
} from './actions'


class BaseState {
    array = [];
    payload = null;
    isFetching = false;
    receivedAt = null;
}



const baseReducer = (state = new BaseState(), action) => {


    switch (action.type) {
        case GET_INIT_DATA:
            return {
                ...state,
                payload: action.payload,
                receivedAt: Date.now()
            }
        default:
            return state;
    }
}



export default baseReducer;
