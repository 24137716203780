//src/app/filter/FilterComponent.js
import React from 'react'
import PropTypes from 'prop-types'
import Drawer from './Drawer'
import { Button, makeStyles, Typography, IconButton, Menu, MenuItem, Grid } from '@material-ui/core'
import HistoryIcon from "@material-ui/icons/History"
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

const FilterComponent = ({ open, onOpenFilter, filters, totalProducts, onGetFilterState, selected, onApplyFilter, onRefresh, onSortBy }) => {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [menuName, setMenuName] = React.useState("Sort By");

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = (direction, name) => {
        setMenuName(name)
        if (direction)
            onSortBy(direction)
        setAnchorEl(null);
    }

    const handleRefresh = () => {
        onRefresh()
        setMenuName("Sort By")
    }

    if (!filters) {
        return <Typography variant="body2" align="center">Loading filter...</Typography>
    }
    return (
        <div className={classes.root}>
            <div className={classes.wrapFilterHeader}>
                <Typography variant="h6">Products ({totalProducts}) <IconButton onClick={handleRefresh}><HistoryIcon /></IconButton></Typography>

                <div>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>

                            <Button style={{ marginRight: 20 }} color="primary" variant="contained" className="btn-default btn-secondary" onClick={() => onOpenFilter(true)}>Filter</Button>

                        </Grid>

                        <Grid item xs={6}>

                            <Button
                                aria-controls="sortby-menu"
                                aria-haspopup="true"
                                onClick={handleClick}
                                color="primary"
                                variant="contained"
                                style={{ whiteSpace: "nowrap" }}
                                className="btn-default btn-primary">{menuName}&nbsp;<ArrowDropDownIcon /></Button>
                            <Menu
                                id="sortby-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={() => handleClose(null, "Sort By")}
                                style={{ backgroundColor: "rgba(0,0,0,0.2)" }}
                            >
                                <MenuItem onClick={() => handleClose("ASC", "Name A to Z")}>Name A to Z</MenuItem>
                                <MenuItem onClick={() => handleClose("DESC", "Name Z to A")}>Name Z to A</MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                </div>


            </div>

            <Drawer
                open={open}
                onHandleDrawerOpen={onOpenFilter}
                filters={filters}
                onGetFilterState={onGetFilterState}
                onApplyFilter={onApplyFilter}
                onRefresh={onRefresh}
                selected={selected} />
        </div>
    )
}


const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: 24
    },
    wrapFilterHeader: {
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            display: "inline-block",
            justifyContent: "space-around"
        }
    }
}))

FilterComponent.propTypes = {
    onOpenFilter: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
}

export default FilterComponent
