// redux store
import { createStore, applyMiddleware, compose } from 'redux';
import { getData } from "./library/actions"
import { loadState, saveState } from './localStorage'
import thunk from 'redux-thunk';
import reducers from './reducers';
import logger from './logger'

// Actions


const persistedState = loadState()
const store = createStore(
    reducers,
    persistedState,
    compose(
        applyMiddleware(logger, thunk),
        // window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    )
)


store.dispatch(getData("COMPANY_THEME"))

store.subscribe(() => {
    saveState({
        auth: store.getState().auth,
        collections: store.getState().collections,
        products: store.getState().products,
        finishings: store.getState().finishings,
        filter: store.getState().filter,
        library: store.getState().library
    })
})





export default store;
