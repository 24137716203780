//src/app/layout/actions.js

const SET_DRAWER = 'SET_DRAWER'
const SET_OFFLINE = "SET_OFFLINE"
const OPEN_POPOVER = 'OPEN_POPOVER'
const SET_DOWNLOADED = 'SET_DOWNLOADED'

const openDrawer = () => {
    return {
        type: SET_DRAWER,
    }
}


const openPopover = (bool, anchorEl) => {
    return {
        type: OPEN_POPOVER,
        bool,
        anchorEl
    }
}

const setOffline = (bool) => {
    return {
        type: SET_OFFLINE,
        bool,
    }
}

const setDownloaded = (bool) => {
    return {
        type: SET_DOWNLOADED,
        bool,
    }
}



export {
    openDrawer,
    openPopover,
    setOffline,
    setDownloaded,

    SET_DRAWER,
    OPEN_POPOVER,
    SET_OFFLINE,
    SET_DOWNLOADED
}
