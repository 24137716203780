//src/app/pages/home.js
import React from 'react'
import LoginContainer from '../auth/LoginContainer'



const Login = () => {
    return <LoginContainer />
}

export default Login;