//src/app/pages/home.js
import React from 'react'
import { Link } from "react-router-dom"
import { makeStyles, Typography } from '@material-ui/core'
// import UploadAssets from '../../lib/uploadAssets'
// import UploadVideo from '../../lib/uploadVideo'
import packageJson from '../../../package.json';
import { sendAll } from '../../services/sendQueued';



const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        height: "100%",
        backgroundColor: "#000",
        padding: 50,
    }

}));

const version = packageJson.version;

const queueEmpty = localStorage.getItem('woodnet-queue', null) ? true : false;

const Footer = (props) => {
    const classes = useStyles();
    return <div className={classes.root}>
        <div style={{ display: "flex", justifyContent: "space-between", }}>
            <div>
                <Typography variant="caption" style={{ color: "gray", fontSize: 11 }}>{new Date().getFullYear()} - &#169; <a href="https://thirtydirtyfingers.com" target="_blank" rel="noopener noreferrer">ThirtyDirtyFingers</a></Typography><br/><br/>
                <Typography variant="caption" style={{ color: "gray", fontSize: 11 }}>App version: {version}</Typography>
            </div>

            <div>
                <Link to="/downloads" style={{ color: "white", fontSize: 13 }}>Access all collections</Link><br/>

                {navigator.onLine &&
                  <div>
                    <p> </p>
                    { !queueEmpty &&
                      <div><a href="javascript:;" onClick={sendAll} style={{ color: "white", fontSize: 13 }}>Send selections</a></div>
                    }
                    <div><Link to="/cache-all" style={{ color: "white", fontSize: 13 }}>Precache all photos for offline</Link></div>

                  </div>
                }
            </div>

        </div>

    </div >
}

export default Footer;
