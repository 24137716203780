import axios from "axios";
import { END_POINT, INDEXED_V } from '../constant'

/**
 * Save data in queue
 * @param {*} data // object of cart
 *
 */
const saveData = (data) => {
    var queue = JSON.parse(localStorage.getItem('woodnet-queue'));
    if(!queue){
      queue = [];
    }

    queue.push({ name: Date.now(), data: data });
    localStorage.setItem('woodnet-queue', JSON.stringify(queue));
    console.log('woodnet-queue', JSON.parse( localStorage.getItem('woodnet-queue', []) ) )
}

/**
 * Send all data when back online
 */
const sendAll = async () => {

    console.log('Sending all selections...');

    var queue = JSON.parse(localStorage.getItem('woodnet-queue'));

    if(!queue){ console.log('no selection to send, queue is empty'); return; }

    const token = JSON.parse(localStorage.getItem("woodnet-state")).auth.token
    const array = []

    var newQueue = [];

    for (let i = 0; i < queue.length; i++) {
        try {

            console.log('queue[i]', queue[i]);

            // const formData = new FormData();
            // formData.set("emails", queue[i].data.emails)
            // formData.set("notes", queue[i].data.notes)
            // formData.set("collections", queue[i].data.collections)

            const params = {
              emails: queue[i].data.emails,
              notes: queue[i].data.notes,
              collections: queue[i].data.collections,
              token: token
            }

            let response = await axios.post(`${END_POINT}create/selection?token=${token}`, params);
            if(response.status != 200){
              newQueue.push(queue[i]);
            }

        } catch (err) {
            console.log("Something wrong", err)
            newQueue.push(queue[i]);
        }
    }

    if(newQueue.length > 0){
      localStorage.setItem('woodnet-queue', JSON.stringify(newQueue));
    } else {
      localStorage.setItem('woodnet-queue', null);
    }

    console.log('Sending selections end');


}
// /**
//  * Delete data from indexedDB
//  * @param {*} array of data name
//  *
//  */
// const _deleteData = (array) => {
//     const request = indexedDB.open('woodnet-database', INDEXED_V);
//     request.onsuccess = event => {
//         const db = event.target.result;
//
//         const transaction = db.transaction(["queued"], "readwrite");
//         const objectStore = transaction.objectStore("queued");
//         const response = objectStore.getAll();
//
//         response.onerror = event => {
//             console.log('error');
//         }
//         for (let i = 0; i < array.length; i++) {
//             objectStore.delete(array[i])
//         }
//     }
// }


export {
    saveData,
    sendAll
}
