import React from 'react';
import { makeStyles, Grid, Button, Typography, MenuItem, TextField } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        width: "100%",
        flexShrink: 0,
    },
    drawerPaper: {
        width: "100%",
    },
    drawerContent: {
        position: 'relative',
        minHeight: 300,
        padding: 24
    },
    btnWrapper: {
        // position: 'absolute',
        bottom: 30
    },
    btnClear: {
        minWidth: "150px !important",
        height: "50px !important",
        transition: "0.2s ease",
        borderRadius: "27px !important",
        backgroundColor: "black !important",
        color: "white !important",
        border: "1px solid black !important",
        float: "right",
        [theme.breakpoints.down("sm")]: {
            marginTop: 20,
            marginBottom: 20,
            float: "none",

        }
    }
}))

const DrawerLeft = (props) => {
    const classes = useStyles();
    const { open, onHandleDrawerOpen, filters, onGetFilterState, onApplyFilter, onRefresh, selected } = props
    if (!filters) {
        return null
    }

    return (
        <div className={classes.root}>
            <Drawer
                className={classes.drawer}
                variant="temporary"
                anchor="top"
                open={open}
                onClose={onHandleDrawerOpen}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerContent}>
                    <Typography variant="h4">Filter</Typography>
                    <br />
                    <Grid container spacing={5}>
                        <Grid xs={12} sm={3} md={3} item>
                            <TextField
                                select
                                label="CATEGORY"
                                name="category_id"
                                fullWidth
                                value={selected.category_id || ""}
                                onChange={(e) => onGetFilterState(e.target.name, e.target.value)}
                                variant="outlined"
                            >
                                {
                                    filters &&
                                    filters.productcategories.map((x, index) => {
                                        return <MenuItem key={index} value={x.id}>{x.name}</MenuItem>
                                    })
                                }
                            </TextField>
                        </Grid>
                    </Grid>
                    <br /><br /><br /><br />
                    <div className={classes.btnWrapper}>
                        <Button color="primary" variant="contained" className="btn-default" onClick={() => onApplyFilter()}>Apply</Button>&nbsp;&nbsp;&nbsp;
                        <Button color="secondary" variant="contained" className="btn-default btn-secondary" onClick={() => onHandleDrawerOpen(false)}>Close</Button>
                        <Button color="primary" variant="contained" className={classes.btnClear} onClick={() => onRefresh()}>Clear filter ({Object.keys(selected).length})</Button>&nbsp;&nbsp;&nbsp;
                    </div>

                </div>
            </Drawer>
        </div >
    );
}


export default DrawerLeft;
