//src/app/pages/collection.js
import React from 'react'
import { connect } from 'react-redux'
import CollectionContainer from '../collection/CollectionContainer'
import ContentLayout from '../layout/contentLayout'



const Collection = (props) => {


    var img_header;
    var text;

    if(!props.selected.type){
     img_header = props.static.img_header;
     text = props.static.title;
   } else {
     text = props.selected.type.toUpperCase();
     img_header = props.imgs[props.selected.type].img || props.static.img_header;
   }

  return <ContentLayout img={img_header} text={text} showHeader={true}>
      <div>
          <CollectionContainer {...props} displayFilter={true} />
      </div>
    </ContentLayout>
}

const mapStateToProps = state => {
    return {
        static: state.library.company_theme.payload.pages.collections,
        imgs:   state.library.company_theme.payload.pages.home.data,
        selected: state.filter.selected,
    }
}


export default connect(mapStateToProps)(Collection);
