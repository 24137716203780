//src/app/home/CollectionComponent.js
import React from 'react'
import { TextField, makeStyles, Button, Grid, Hidden, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'


const LoginComponent = (props) => {
    const classes = useStyles();

    return (
        <Grid container spacing={0}>

            <Grid item xs={12} md={6} sm={6}>
                <div className={classes.root} style={{ height: props.height }}>
                    <div className={classes.content}>
                        {props.message ? <Typography variant="h6" align="left" style={{ color: "red", marginBottom: 10 }}>{props.message}</Typography> : null}
                        <form onSubmit={props.onSubmitLogin}>
                            <TextField
                                label="email"
                                name="email"
                                className={classes.textField}
                                style={{ minWidth: 300 }}
                                required
                                variant="outlined"
                                onChange={(e) => props.onChangeCredential(e.target.name, e.target.value)}
                                value={props.auth.email || ""}
                            /><br />
                            <TextField
                                label="password"
                                name="password"
                                variant="outlined"
                                type="password"
                                className={classes.textField}
                                style={{ minWidth: 300 }}
                                required
                                onChange={(e) => props.onChangeCredential(e.target.name, e.target.value)}
                                value={props.auth.password || ""}
                            />

                            <br />
                            <Button disabled={props.isFetching} type="submit" variant="contained" className="btn-default btn-primary" fullWidth endIcon={<ArrowRightIcon />}>{props.isFetching ? "Loading..." : "Connect"}</Button>
                        </form>
                    </div>
                </div >
            </Grid>
            <Hidden xsDown>
                <Grid item sm={6} md={6}>
                    <div style={{
                        height: props.height,
                        backgroundImage: props.theme.pages ? `url(${props.theme.pages.login.img_header})` : null,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center"
                    }}>
                    </div>
                </Grid>
            </Hidden>
        </Grid >

    )
}

LoginComponent.propTypes = {
    onSubmitLogin: PropTypes.func.isRequired
}


const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down('xs')]: {
            // display: "block",
            // marginTop: '10%',
            width: "100%",

        }
    },
    content: {
        textAlign: "center",
        // margin: 10
    },
    textField: {
        marginBottom: 24
    }
}))

export default LoginComponent
