//src/app/home/collectionsReducer.js

import {
    REQUEST_AUTH,
    RECEIVE_AUTH,
    FAILED_AUTH,
    STATE_AUTH,
    LOGOUT_AUTH
} from './actions'

const initialState = {
    isFetching: false,
    isAuthenticated: false,
    isError: false,
    message: null,
    list: [],
    item: null,
    user: null,
    token: null
}

const collectionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_AUTH:
            return {
                ...state,
                isFetching: true,
                isError: false,
                message: null

            }
        case RECEIVE_AUTH:
            return {
                ...state,
                isFetching: false,
                isAuthenticated: true,
                user: action.user,
                token: action.token,
                item: null,
                message: null
            }
        case LOGOUT_AUTH:
            return {
                ...state,
                isAuthenticated: false,
                isFetching: false,
                user: null,
                token: null,
                item: null,
                message: null
            }
        case STATE_AUTH:
            return {
                ...state,
                isFetching: false,
                isError: false,
                item: { ...state.item, [action.payload.fieldName]: action.payload.value },
                message: null
            }
        case FAILED_AUTH:
            return {
                ...state,
                isFetching: false,
                isAuthenticated: false,
                message: "Username or password incorrect",
                isError: true
            }
        default:
            return state
    }
}

export default collectionsReducer