import React from 'react'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
    root: {
      height: '300px',
      width: '400px'
    }

})


class SirvSpin extends React.Component {

    render() {
        const {classes, url} = this.props;

        if(!url){ return <></>; }
        else{
          return <div className={classes.root}>

              <div class="Sirv" data-src={url}></div>

          </div >
        }

    }

}

export default withStyles(styles)(SirvSpin);
