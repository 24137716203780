//src/app/pages/panier.js
import React from 'react'
import { connect } from 'react-redux'
import { Link } from "react-router-dom"
import { addEmail, removeFromCart, addNotes, removeEmail, sendCart } from '../cart/actions'
import { Typography, makeStyles, Button, TextField, Chip, Grid } from '@material-ui/core'
import ContentLayout from '../layout/contentLayout'
import DeleteIcon from '@material-ui/icons/Delete'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import AddIcon from '@material-ui/icons/Add'
import { openPopover } from '../layout/actions'
//import Img from '../common/img'

const height = window.innerHeight - (173 + 150)

const useStyles = makeStyles(theme => ({
    sectionCart: {
        marginBottom: 24,
        marginTop: 45
    },
    sectionNotes: {
        clear: "both",
        marginBottom: 24
    },
    cardWrapper: {
        display: "flex",
        justifyContent: "space-around",
        maxWidth: 203,
        height: 78,
        [theme.breakpoints.down("xs")]: {
            maxWidth: "100%"
        }
    },
    textWrap: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    textField: {
        minWidth: 400,
        [theme.breakpoints.down("xs")]: {
            width: "100%"
        }
    },
    btnAddEmail: {
        marginLeft: 15,
        height: 54,
        [theme.breakpoints.down("xs")]: {
            marginLeft: 0,
            marginTop: 10
        }
    },
    emailWrapper: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            display: "block",
        }
    }

}));


const Cart = (props) => {
    const classes = useStyles();
    const [email, setEmail] = React.useState(null);

    const validateEmail = (email) => {
        var re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        return re.test(String(email).toLowerCase());
    }

    const handleAddEmail = (email) => {
        if (!email) {
            return;
        }
        if (!validateEmail(email)) {
            alert("Please enter a correct email format")
            return
        }
        setEmail("")
        props.addEmail(email.replace(/ /g, ""))
    }

    const handleDelete = (email) => {
        props.removeEmail(email)
    }

    return <ContentLayout img={props.staticContent.img_header} text={props.staticContent.title} showHeader={false}>
        <div style={{ minHeight: height, marginTop: props.online ? 0 : -94 }}>
            <div className={classes.sectionCart}>
                <Typography style={{ padding: 20, backgroundColor: "#f9f9f9", borderRadius: 4 }} variant="h3" align="center">{props.staticContent.title} ({props.selections.length})</Typography>
                <Grid container spacing={5} style={{ marginTop: 20 }}>
                    {
                        props.selections.map((item, index) => {
                            return <Grid item xs={12} sm={3} md={3} key={index}>
                                <div className={classes.cardWrapper}>
                                    <div style={{ display: "flex", backgroundColor: "#f9f9f9", alignItems: "center" }}>
                                        {
                                            item.images.length > 0 &&
                                                <Link to={`/collections/${item.id}`} onClick={() => props.openPopover(false)}>
                                                    <img
                                                        alt="app-catalogue"
                                                        src={item.images[0].path}
                                                        width="80"
                                                    />
                                                </Link>
                                        }
                                    </div>
                                    <div className={classes.textWrap}>
                                        <Link to={`/collections/${item.id}`} onClick={() => props.openPopover(false)}><Typography variant="body1">{item.name}</Typography></Link>
                                        <Typography style={{ fontSize: 13, cursor: "pointer" }} onClick={() => props.removeFromCart(item)} variant="body1"><DeleteIcon />&nbsp;Remove</Typography>
                                    </div>
                                </div>

                            </Grid>
                        })
                    }
                </Grid>
            </div>

            <div className={classes.sectionNotes}>
                <Typography variant="h6" style={{ marginBottom: 20 }}>Add notes (optional)</Typography>

                <TextField
                    fullWidth
                    rows="5" multiline
                    variant="outlined"
                    margin="dense"
                    value={props.notes || ""}
                    label="Type here to add notes..."
                    onChange={(e) => props.addNotes(e.target.value)} />
            </div>


            <div className={classes.sectionEmails}>
                <Typography
                    variant="h6" style={{ marginBottom: 20 }}>Please make sur the email address is correct</Typography>
                <div className={classes.emailWrapper}>
                    <TextField
                        variant="outlined"
                        value={email || ""}
                        className={classes.textField}
                        label="Type an email"
                        onKeyPress={(e) => { if (e.key === "Enter") { handleAddEmail(email) } }}
                        onChange={(e) => setEmail(e.target.value)} />
                    <Button
                        className={classes.btnAddEmail}
                        color="default"
                        variant="outlined"
                        onClick={() => handleAddEmail(email)} > <AddIcon />&nbsp;&nbsp;Add another email address</Button>
                </div>


                <div style={{ clear: "both", marginTop: 20 }}>
                    {
                        props.emails.map((x, index) => {
                            return <Chip style={{ marginRight: 10, marginBottom: 10 }} key={index} label={x} onDelete={() => handleDelete(x)} />
                        })
                    }
                </div>
                <div style={{ marginTop: 50 }}>
                    {props.emails.length > 0 && props.selections.length > 0 ? <Button color="primary" component={Link} to="/confirm-cart" className="btn-default btn-primary" variant="contained">Export List&nbsp;<ArrowRightIcon /></Button> : null}
                    &nbsp;&nbsp;<Button color="primary" component={Link} to="/collections" className="btn-default btn-secondary" variant="contained">Close</Button>

                </div>
            </div>
        </div>
    </ContentLayout >
}


const mapStateToProps = state => {

    return {
        selections: state.cart.list,
        emails: state.cart.emails,
        notes: state.cart.notes,
        isFetching: state.cart.isFetching,
        token: state.auth.token,
        online: state.layout.online,
        staticContent: state.library.company_theme.payload.pages.cart || { title: "Cart summary", img_header: "https://woodnet.acte.solutions/storage/app/media/collections/img-header.jpg" }

    }
}


export default connect(mapStateToProps, { addEmail, removeFromCart, addNotes, removeEmail, sendCart, openPopover })(Cart);
