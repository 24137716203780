//src/app/home/CollectionComponent.js
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from "@material-ui/core"
import Thumbnail from '../common/thumbnail'


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
}));

// Check if item in current cart exist
const isSelected = (selections, id) => {
    return selections.includes(id)
}

const CollectionComponent = ({ onViewCollection, collections, onAddToCart, showCartBtn, selections, onRemoveFromCart, token, isClient }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                {collections &&
                    collections.map((value, index) => (
                        <Grid key={index} item xs={12} sm={6} md={4}>
                            <Thumbnail
                                isSelected={isSelected(selections, value.id)}
                                showCartBtn={showCartBtn}
                                clickAction={onAddToCart}
                                onRemoveFromCart={onRemoveFromCart}
                                key={index}
                                item={value}
                                collectionId={value.id}
                                token={token}
                                onClick={onViewCollection}
                                isClient={isClient} />
                        </Grid>
                    ))
                }
                {(!collections || collections.length < 1) &&
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body1" align="left">No result found !</Typography>
                  </Grid>
                }
            </Grid>
        </div>

    )
}


CollectionComponent.propTypes = {
    onViewCollection: PropTypes.func.isRequired,
    collections: PropTypes.array.isRequired,
}


export default CollectionComponent
