//src/app/home/CollectionComponent.js
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from "@material-ui/core"
import Thumbnail from '../common/thumbnail'


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
}));

const ProductComponent = ({ products, token, isClient }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                {products &&
                    products.map((value, index) => (
                        <Grid key={index} item xs={12} sm={6} md={4}>
                            <Thumbnail
                              showCartBtn={false}
                              key={index}
                              item={value}
                              collectionId={value.collection_id}
                              productId={value.images[0].id}
                              token={token}
                              isClient={isClient} />
                        </Grid>
                    ))
                }
            </Grid>
        </div>

    )
}


ProductComponent.propTypes = {
    products: PropTypes.array.isRequired,
}


export default ProductComponent
