//src/app/layout/layoutReducer.js

import {
    SET_DRAWER,
    OPEN_POPOVER,
    SET_OFFLINE,
    SET_DOWNLOADED
} from './actions'


const initialState = {
    open: false,
    openPop: false,
    anchorEl: null,
    online: false,
    downloaded: false
}


const layoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DRAWER:
            return {
                ...state,
                open: !state.open
            }
        case OPEN_POPOVER:
            return {
                ...state,
                openPop: action.bool,
                anchorEl: action.anchorEl
            }
        case SET_OFFLINE:
            return {
                ...state,
                online: action.bool
            }
        case SET_DOWNLOADED:
            return {
                ...state,
                downloaded: action.bool
            }
        default:
            return state;
    }
}

export default layoutReducer;