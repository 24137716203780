//src/app/pages/home.js
import React from 'react'
import { connect } from 'react-redux'
import { Typography, Grid, makeStyles } from '@material-ui/core'
import ContentLayout from '../layout/contentLayout'

const useStyles = makeStyles(theme => ({
    wrapper: {
        marginBottom: 48
    }
}));


const renderThumbnail = (array, defaultImg, token) => {
    return array.map((x, index) => {
        let img = x.image;
        return <Grid item xs={6} sm={3} md={2} key={index}>
            <div style={{ height: 140, width: "auto" }} >
                <img src={img.path} alt={x.code} style={{ height: "100%", width: "100%" }} />
                <Typography variant="body2">{x.name}</Typography>
            </div>
        </Grid>
    })
}

const Finishing = (props) => {
    const classes = useStyles();


    return <ContentLayout img={props.staticContent.img_header} text={props.staticContent.title} showHeader={true}>
        <div className={classes.wrapper}>

            {props.finishings && props.finishings.length > 0 &&

              props.finishings.map((item, key) => {

                return <Grid container style={{marginBottom: "64px"}}>
                  <Typography variant="h4" style={{marginBottom: "25px"}} align="left">{item.name}</Typography>
                  <Grid container spacing={8}>
                    {renderThumbnail(item.finishings, props.defaultImg, props.token)}
                  </Grid>
                </Grid>
              })
            }

        </div>

    </ContentLayout >
}

const mapStateToProps = state => {
    return {
        finishings: state.finishings.list,
        defaultImg: state.library.company_theme.payload.default_img,
        token: state.auth.token,
        staticContent: state.library.company_theme.payload.pages.finishing || { title: "Finishing", img_header: "https://woodnet.acte.solutions/storage/app/media/collections/img-header.jpg" }
    }
}


export default connect(mapStateToProps)(Finishing);
