//src/app/cart/card.js
import React from 'react'
import { Link } from 'react-router-dom'
import { Typography, makeStyles, IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
//import Img from '../common/img'

import defaultImage from "../../assets/default-image.png"

const Card = (props) => {
    const classes = useStyles();
    const { item, onRemoveFromCart } = props

    if (!item) { return null; }

    return (
        <div className={classes.root}>
            <div className={classes.img}>
                {
                    item.images && item.images.length > 0 ?
                        <Link to={`/collections/${item.id}`} onClick={() => props.openPopover(false)}>
                            <img src={item.images[0].path} alt="woodnet" width="50" />
                        </Link>
                        : <img src={defaultImage} alt="default" width="50" />
                }

            </div>

            <div className={classes.wrap}>
                <Link to={`/collections/${item.id}`} onClick={() => props.onOpenPopover(false)}><Typography>{item.name}</Typography></Link>
                <div><IconButton onClick={() => onRemoveFromCart(item)}><DeleteIcon /></IconButton></div>
            </div>


        </div>
    )
}



const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        justifyContent: "flex-start",
        marginBottom: 24
    },
    img: {
        marginRight: 12,
    },
    wrap: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        marginRight: 10
    }
}))

export default Card;
