import React from 'react'
import { Typography } from '@material-ui/core'


const Offline = () => {
    return (
        <div style={{ backgroundColor: "#aa363b", position: "fixed", width: "100%", textAlign: "center", padding: 20, zIndex: 1000 }}>
            <Typography variant="body2" style={{ color: "white" }}>Offline version: refreshing the website will cause the loss of data</Typography>
        </div>
    )
}

export default Offline;
