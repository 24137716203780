import {
    REQUEST_PRODUCTS,
    RECEIVE_PRODUCTS,
    FAILED_PRODUCTS,
    GET_PRODUCT,
    SAVED_INITIAL_PRODUCT_LIST,
} from './actions'

const initialState = {
    isFetching: false,
    isError: false,
    list: [],
    unFilteredList: [],
    item: null
}

const productsReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_PRODUCTS:
            return {
                ...state,
                isFetching: true,
                isError: false
            }
        case RECEIVE_PRODUCTS:
            return {
                ...state,
                isFetching: false,
                isError: false,
                list: action.products
            }
        case SAVED_INITIAL_PRODUCT_LIST:
            return {
                ...state,
                unFilteredList: action.products
            }
        case GET_PRODUCT:

            return {
                ...state,
                isFetching: false,
                isError: false,
                item: action.payload
            }
        case FAILED_PRODUCTS:
            return {
                ...state,
                isFetching: false,
                isError: true,
                list: action.products
            }
        default:
            return state
    }
}

export default productsReducer
