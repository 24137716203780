//src/app/pages/about.js
import React from 'react'
import { connect } from 'react-redux'
import { Typography, Grid, Hidden, makeStyles } from '@material-ui/core'
import Video from '../common/video'
import FactComponent from '../common/fact'

import ContentLayout from '../layout/contentLayout'
import WorldMap from '../../assets/world_map.svg'

const useStyles = makeStyles(theme => ({
    mapWrapper: {
        backgroundColor: "#f9f9f9",
        padding: 54,
        [theme.breakpoints.down("sm")]: {
            padding: 12
        }
    }
}))
const About = (props) => {
    const classes = useStyles()

    console.log(props.facts)

    return <ContentLayout img={props.about.img_header} text={props.about.title} showHeader={true}>
        <div>
            <Grid container justify="space-between" spacing={0} style={{ padding: "5%" }}>
                <Grid item xs={12} sm={6} md={6}>

                    <Typography variant="h3" align="left" style={{ marginBottom: 24 }}>{props.about.subtitle}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="body2" style={{ color: "#919191" }} dangerouslySetInnerHTML={{ __html: props.about.paragraphe }}></Typography>
                    <p><a href="http://woodnet-ltd.net" target="_blank" rel="noreferrer">Visit our website</a></p>
                </Grid>
            </Grid>
            <div className={classes.mapWrapper}>
                <Grid container spacing={0} >
                    <Hidden only={["sm", "md", "lg"]}>
                        <Grid item xs={12} sm={6} md={6}>
                            <img style={{ maxWidth: "100%" }} src={WorldMap} alt="world-map" height="337" />
                        </Grid>
                    </Hidden>

                    <Grid item xs={12} sm={6} md={6} style={{ alignItems: "center", display: "flex" }}>

                        <Grid container justify="space-between" spacing={5} >

                          <Grid item xs={12}>
                          <Typography variant="h1" style={{ whiteSpace: "nowrap" }}>A FEW NUMBERS</Typography>
                          </Grid>

                          { props.facts &&
                            props.facts.map((item, key) => (
                              <FactComponent key={key} fact={item}/>
                            ))
                          }

                        </Grid>

                    </Grid>
                    <Hidden only={["xs"]}>
                        <Grid item xs={12} sm={6} md={6}>
                            <img style={{ maxWidth: "100%" }} src={WorldMap} alt="world-map" height="337" />
                        </Grid>
                    </Hidden>

                </Grid>
            </div>

            {
                props.about.link_video ?
                    <div style={{ marginTop: 48 }}>
                        <Video video={props.about.link_video} />
                    </div>
                    : <div><br /> <br /> <br /></div>
            }

        </div >
    </ContentLayout >
}

const mapStateToProps = state => {
    return {
        about: state.library.company_theme.payload.pages.about_us,
        facts: state.library.company_theme.payload.facts,
        isMobile: true,
        token: state.auth.token
    }
}


export default connect(mapStateToProps)(About);
