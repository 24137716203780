//src/app/common/contentLayout.js
import React from 'react'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core'
import Header from '../common/header'


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: 48,
        [theme.breakpoints.down('sm')]: {
            padding: 12
        }
    },

}));



const ContentLayout = (props) => {
    const classes = useStyles();

    return <div>
        {
            props.showHeader ? <Header
                online={props.online}
                img={props.img}
                text={props.text} />
                : null
        }

        <div className={classes.root} style={{ marginTop: props.showHeader ? 0 : 64 }}>

            <main>{props.children}</main>
        </div>
    </div>
}

const mapStateToProps = state => {
    return {
        online: state.layout.online
    }
}



export default connect(mapStateToProps)(ContentLayout);