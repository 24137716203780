import React from 'react';

import {
  makeStyles,
  Grid,
  Button,
  Typography,
  MenuItem,
  TextField ,
  FormControl,
  InputLabel,
  Select,
  Input,
  Checkbox,
  ListItemText

} from '@material-ui/core';

import Drawer from '@material-ui/core/Drawer';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        width: "100%",
        flexShrink: 0,
    },
    drawerPaper: {
        width: "100%",
    },
    drawerContent: {
        position: 'relative',
        minHeight: 300,
        padding: 24
    },
    btnWrapper: {
        // position: 'absolute',
        bottom: 30
    },
    btnClear: {
        minWidth: "150px !important",
        height: "50px !important",
        transition: "0.2s ease",
        borderRadius: "27px !important",
        backgroundColor: "black !important",
        color: "white !important",
        border: "1px solid black !important",
        float: "right",
        [theme.breakpoints.down("sm")]: {
            marginTop: 20,
            marginBottom: 20,
            float: "none",

        }
    }
}))


const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 50;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const DrawerLeft = (props) => {
    const classes = useStyles();
    const { open, onHandleDrawerOpen, filters, onGetFilterState, onApplyFilter, onRefresh, selected } = props

    if (!filters) { return null }

    return (
        <div className={classes.root}>
            <Drawer
                className={classes.drawer}
                variant="temporary"
                anchor="top"
                open={open}
                onClose={onHandleDrawerOpen}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerContent}>
                    <Typography variant="h4">Filter</Typography>
                    <br />
                    <Grid container spacing={5}>
                        <Grid xs={12} sm={3} md={3} item>
                            <TextField
                                select
                                label="Type"
                                name="type"
                                fullWidth
                                value={selected.type || ""}
                                onChange={(e) => onGetFilterState(e.target.name, e.target.value)}
                                variant="outlined"
                            >
                                {
                                    filters &&
                                    filters.collectiontypes.map((x, index) => {
                                        return <MenuItem key={index} value={x.code}>{x.name}</MenuItem>
                                    })
                                }
                            </TextField>
                        </Grid>
                        <Grid xs={12} sm={3} md={3} item>
                            <FormControl style={{minWidth: '100%'}}>
                              <InputLabel id="country-available-in">Available in</InputLabel>
                              <Select
                                labelid="country-available-in"
                                id="country_available_multiple"
                                multiple
                                value={selected.country_available_multiple || []}
                                onChange={(e) => onGetFilterState("country_available_multiple", e.target.value)}
                                input={<Input />}
                                renderValue={(selec) =>
                                  selec &&
                                    selec.length > 0 &&
                                    selec.map((item,key) => <span>{item.name}, </span>)
                                  }
                                MenuProps={MenuProps}
                              >
                                {
                                    filters &&
                                    filters.countries_available.map((item, key) => {
                                        return(
                                          <MenuItem key={item.id} value={item}>
                                            <Checkbox checked={selected.country_available_multiple ? selected.country_available_multiple.indexOf(item) > -1 : false} />
                                            <ListItemText primary={item.name} />
                                          </MenuItem>
                                        );
                                    })
                                }
                              </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} sm={3} md={3} item>
                          <FormControl style={{minWidth: '100%'}}>
                            <InputLabel id="country-sold">Sold to</InputLabel>
                            <Select
                              labelid="country-sold"
                              id="country_sold_multiple"
                              multiple
                              value={selected.country_sold_multiple || []}
                              onChange={(e) => onGetFilterState("country_sold_multiple", e.target.value)}
                              input={<Input />}
                              renderValue={(selec) =>
                                selec &&
                                  selec.length > 0 &&
                                  selec.map((item,key) => <span>{item.name}, </span>)
                                }
                              MenuProps={MenuProps}
                            >
                              {
                                  filters &&
                                  filters.countries_sold.map((item, key) => {
                                      return(
                                        <MenuItem key={item.id} value={item}>
                                          <Checkbox checked={selected.country_sold_multiple ? selected.country_sold_multiple.indexOf(item) > -1 : false} />
                                          <ListItemText primary={item.name} />
                                        </MenuItem>
                                      );
                                  })
                              }
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid xs={12} sm={3} md={3} item>
                            <TextField
                                select
                                label="FOB"
                                name="fob_id"
                                fullWidth
                                value={selected.fob_id || ""}
                                onChange={(e) => onGetFilterState(e.target.name, e.target.value)}
                                variant="outlined"
                            >
                                {
                                    filters &&
                                    filters.fobs.map((x, index) => {
                                        return <MenuItem key={index} value={x.id}>{x.name}</MenuItem>
                                    })
                                }
                            </TextField>
                        </Grid>
                        <Grid xs={12} sm={3} md={3} item>
                            <TextField
                                select
                                label="Factory"
                                name="factory_id"
                                fullWidth
                                value={selected.factory_id || ""}
                                onChange={(e) => onGetFilterState(e.target.name, e.target.value)}
                                variant="outlined"
                            >
                                {
                                    filters &&
                                    filters.factories.map((x, index) => {
                                        return <MenuItem key={index} value={x.id}>{x.name}</MenuItem>
                                    })
                                }
                            </TextField>
                        </Grid>
                        <Grid xs={12} sm={3} md={3} item>
                            <TextField
                                select
                                label="Materials"
                                name="materials"
                                fullWidth
                                value={selected.materials || ""}
                                onChange={(e) => onGetFilterState(e.target.name, e.target.value)}
                                variant="outlined"
                            >
                                {
                                    filters &&
                                    filters.materials.map((x, index) => {
                                        return <MenuItem key={index} value={x.id}>{x.name}</MenuItem>
                                    })
                                }
                            </TextField>
                        </Grid>
                        <Grid xs={12} sm={3} md={3} item>
                            <TextField
                                select
                                label="Finishing"
                                name="finishing"
                                fullWidth
                                value={selected.finishing || ""}
                                onChange={(e) => onGetFilterState(e.target.name, e.target.value)}
                                variant="outlined"
                            >
                                {
                                    filters &&
                                    filters.finishing.map((x, index) => {
                                        return <MenuItem key={index} value={x.id}>{x.name}</MenuItem>
                                    })
                                }
                            </TextField>
                        </Grid>
                        <Grid xs={12} sm={3} md={3} item>
                            <TextField
                                select
                                label="Textures"
                                name="textures"
                                fullWidth
                                value={selected.textures || ""}
                                onChange={(e) => onGetFilterState(e.target.name, e.target.value)}
                                variant="outlined"
                            >
                                {
                                    filters &&
                                    filters.textures.map((x, index) => {
                                        return <MenuItem key={index} value={x.id}>{x.name}</MenuItem>
                                    })
                                }
                            </TextField>
                        </Grid>
                        <Grid xs={12} sm={3} md={3} item>
                            <TextField
                                select
                                label="Styles"
                                name="style_id"
                                fullWidth
                                value={selected.style_id || ""}
                                onChange={(e) => onGetFilterState(e.target.name, e.target.value)}
                                variant="outlined"
                            >
                                {
                                    filters &&
                                    filters.styles.map((x, index) => {
                                        return <MenuItem key={index} value={x.id}>{x.name}</MenuItem>
                                    })
                                }
                            </TextField>
                        </Grid>
                        <Grid xs={12} sm={3} md={3} item>
                            <TextField
                                select
                                label="Year"
                                name="year_id"
                                fullWidth
                                value={selected.year_id || ""}
                                onChange={(e) => onGetFilterState(e.target.name, e.target.value)}
                                variant="outlined"
                            >
                                {
                                    filters &&
                                    filters.years.map((x, index) => {
                                        return <MenuItem key={index} value={x}>{x}</MenuItem>
                                    })
                                }
                            </TextField>
                        </Grid>
                    </Grid>
                    <br /><br /><br /><br />
                    <div className={classes.btnWrapper}>
                        <Button color="primary" variant="contained" className="btn-default" onClick={() => onApplyFilter()}>Apply</Button>&nbsp;&nbsp;&nbsp;
                        <Button color="secondary" variant="contained" className="btn-default btn-secondary" onClick={() => onHandleDrawerOpen(false)}>Close</Button>
                        <Button color="primary" variant="contained" className={classes.btnClear} onClick={() => onRefresh()}>Clear filter ({Object.keys(selected).length})</Button>&nbsp;&nbsp;&nbsp;
                    </div>

                </div>
            </Drawer>
        </div >
    );
}


export default DrawerLeft;
