//src/routes/NotFound.js
import React from 'react'


const NotFound = () => {
    return <div style={{ textAlign: 'center', marginTop: "20%" }}>
        <h1>Page not found</h1>
    </div>
}

export default NotFound