import React from 'react';
import { NavLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Fab from '@material-ui/core/Fab';
import MenuIcon from '@material-ui/icons/Menu';
import Popover from '@material-ui/core/Popover';
import CartContainer from '../cart/CartContainer'
import OfflineComposant from '../common/offline'

import { connect } from 'react-redux'
import { resetResult } from '../filter/actions'



const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,

    },
    appBar: {
        // paddingTop: theme.spacing.unit * 1,
        // paddingBottom: theme.spacing.unit * 1,
        paddingTop: 20,
        paddingBottom: 20,
        backgroundColor: "white",
        boxShadow: "none",
        borderBottom: "1px solid #efefef",
        zIndex: 990
    },
    active: {
        color: "black",
        "& span": {
            fontWeight: "700 !important"
        }

    },
    menu: {
        "& span": {
            color: "black",
            fontWeight: 400,
            textTransform: "none",
            fontSize: 15
        }
    },
    menuButton: {
        marginRight: theme.spacing.unit * 2,
        color: "gray",
        [theme.breakpoints.up('md')]: {
            display: 'none',
        }
    },
    title: {
        // display: 'block',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    wrapLogo: {
        marginLeft: "30%",
        [theme.breakpoints.up('sm')]: {
            marginLeft: 0
        },
    },
    leftMenu: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        marginRight: theme.spacing.unit * 2,
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing.unit * 3,
            width: 'auto',

        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        color: "gray"
    },
    sectionDesktop: {
        display: 'none',
        color: "gray",
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    wrap: {
        marginRight: 30
    },
    text: {
        color: "black",
        fontSize: 14,
        marginRight: 10
    }
}));

const MenuBar = ({condition, onRefresh, openPop, onHandleDrawerOpen, handleOpenPopover, theme, isClient, selections, onLogout}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isOffline = condition === "offline" ? true : false
    // const isMenuOpen = Boolean(anchorEl);

    const handleCartMenuOpen = event => {
        setAnchorEl(event.currentTarget);
        handleOpenPopover(true)
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleOpenPopover(false)
    };


    const menuId = 'cart-popover';
    const renderMenu = (
        <Popover
            id={menuId}
            open={openPop}
            anchorEl={anchorEl}
            onClose={handleMenuClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <CartContainer onClose={handleMenuClose} />
        </Popover>
    )

    return (
        <div className={classes.grow}>
            {isOffline ? <div><OfflineComposant /><br /><br /><br /></div> : null}
            <AppBar className={classes.appBar} style={{ marginTop: isOffline ? 0 : 0, position: isOffline ? "sticky" : "fixed" }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => onHandleDrawerOpen()}
                    >
                        <MenuIcon />
                    </IconButton>
                    <div className={classes.wrapLogo}>
                      <a href="http://woodnet-ltd.net" target="_blank" rel="noreferrer">
                        <Typography className={classes.title} variant="h6" noWrap>
                              <img src={theme ? theme.logo : null} alt="woodnet-logo" height="54" />
                        </Typography>
                      </a>
                    </div>

                    <div className={classes.leftMenu}>
                        {
                            theme.top_left_menu.map((menu, index) => {
                                return <Button
                                    key={index}
                                    left="YES"
                                    exact
                                    className={classes.menu}
                                    color="inherit"
                                    component={NavLink}
                                    to={menu.url}
                                    onClick={onRefresh}
                                    activeClassName={classes.active}
                                >{menu.name}</Button>
                            })
                        }
                    </div>
                    <div className={classes.grow} />
                    <div className={classes.sectionDesktop}>
                      {!isClient ?
                        <div className={classes.wrap}>
                            <Typography variant="caption" className={classes.text}>Selection</Typography>
                            <Fab
                                edge="end"
                                size="small"
                                aria-label="selections"
                                aria-controls={menuId}
                                onClick={handleCartMenuOpen}
                                color="secondary"
                                className="btn-secondary"
                                style={{ border: '1px solid black' }}
                            >
                                {selections}
                            </Fab>
                        </div>
                        : null}

                        <div>
                            <Typography variant="caption" className={classes.text}>Disconnect</Typography>
                            <Fab
                                edge="end"
                                size="small"
                                aria-label="disconnect user"
                                aria-controls={menuId}
                                onClick={onLogout}
                                color="primary"
                            >
                                <ExitToAppIcon />
                            </Fab>
                        </div>

                    </div>
                </Toolbar>
            </AppBar>

            {renderMenu}
        </div >
    );
}


const mapDispatchToProps = dispatch => {
    const onRefresh = () => dispatch(resetResult())

    return {
        onRefresh
    }
}

export default connect(null, mapDispatchToProps)(MenuBar)
