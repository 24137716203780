//src/app/pages/collection.js
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { END_POINT } from '../../constant'
import axios from 'axios'

const CacheAll = (props) => {

  const [images, setImages] = useState([]);

  const getFiles = async () => {

    console.log('getFiles');

    try {
        //get files
        const request = await axios.get(`${END_POINT}files?token=${props.token}`)
        const res = request.data
        console.log(res);
        setImages(res);
    } catch (err) {
        console.log("error", err)
    }

  }

  const hideImage = (el) => {
    //console.log(el.target.id, el);
    el.target.style.display = "none";
    el.target.style.height = "0px";
    el.target.style.width = "0px";
    el.target.style.marginBottom = "0px";
  }

  useEffect(() => { getFiles(); }, []);

    return <div style={{paddingTop: "100px"}}>

      <h2>Let this page to load fully until you don't see anymore photo</h2>
      {
            images &&
            images.length > 0 &&
            images.map((x, index) => {

                let imgId = `img-${index}`;

                return <img
                  onLoad={(el) => { hideImage(el); }}
                  id={imgId}
                  loading="lazy"
                  key={index}
                  height="400"
                  src={x}
                  alt="woodnet"
                  style={{marginBottom: "1000px"}}
                  />
            })
      }
    </div>

  }


const mapStateToProps = state => {
      return {
          token: state.auth.token
      }
}

export default connect(mapStateToProps)(CacheAll);
