//src/app/pages/panier.js
import React from 'react'
import { connect } from 'react-redux'
import { Link } from "react-router-dom"
import { removeEmail, sendCart } from '../cart/actions'
import { Typography, makeStyles, Button, Chip, Grid, Divider } from '@material-ui/core'
import ContentLayout from '../layout/contentLayout'

const height = window.innerHeight - (173 + 150)


const useStyles = makeStyles(theme => ({
    sectionCart: {
        marginBottom: 24,
        marginTop: 45
    }
}))


const Cart = (props) => {
    const classes = useStyles();

    return <ContentLayout img={null} text="" showHeader={false}>
        <div style={{ minHeight: height }}>
            <div className={classes.sectionCart}>
                <Typography style={{ padding: 20, backgroundColor: "#f9f9f9", borderRadius: 4 }} variant="h3" align="center">Confirmation</Typography>
                <Grid container spacing={5} style={{ marginTop: 20 }}>
                    <Grid item xs={6}>
                        <Typography variant="h6">Product list</Typography>
                        <Divider />
                        <Grid container spacing={0}>
                            {
                                props.selections.map((item, index) => {
                                    return <Grid item xs={6} key={index}>
                                        <Typography variant="body2">{item.name}</Typography>
                                    </Grid>
                                })
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h6">Notes</Typography>
                        <Divider />
                        <Typography variant="body2">
                            {props.notes}
                        </Typography>
                    </Grid>

                </Grid>
            </div>

            <div className={classes.sectionEmails}>
                <Typography variant="h6">Exporting and sending to:</Typography>
                <Divider />
                <div style={{ clear: "both", marginTop: 20 }}>
                    {
                        props.emails.map((x, index) => {
                            return <Chip style={{ marginRight: 10, marginBottom: 10 }} key={index} label={x} />
                        })
                    }
                </div>
                <div style={{ marginTop: 50 }}>
                    <Button color="primary" disabled={props.isFetching} className="btn-default btn-primary" variant="contained" onClick={() => props.sendCart()} >{props.isFetching ? "Loading..." : "Send List"} </Button>&nbsp;&nbsp;
                    <Button color="primary" component={Link} to="/cart" className="btn-default btn-secondary" variant="contained">Edit</Button>
                </div>
            </div>
        </div>
    </ContentLayout>
}


const mapStateToProps = state => {

    return {
        selections: state.cart.list,
        emails: state.cart.emails,
        notes: state.cart.notes,
        isFetching: state.cart.isFetching
    }
}


export default connect(mapStateToProps, { removeEmail, sendCart })(Cart);

