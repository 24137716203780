import React, { Component } from 'react'
import { connect } from 'react-redux'
import { removeFromCart } from './actions'
import { openPopover } from '../layout/actions'
import Popover from './Popover'


class CartContainer extends Component {

    render() {
        return (
            <Popover
                onClose={this.props.onClose}
                selectionList={this.props.selections}
                onRemoveFromCart={this.props.onRemoveFromCart}
                token={this.props.token}
                onOpenPopover={this.props.onOpenPopover}

            />
        )
    }
}

const mapStateToProps = state => {

    return {
        selections: state.cart.list,
        token: state.auth.token
    }
}

const mapDispatchToProps = dispatch => {
    const onRemoveFromCart = (item) => dispatch(removeFromCart(item))
    const onOpenPopover = (item) => dispatch(openPopover(item))

    return {
        onRemoveFromCart,
        onOpenPopover
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CartContainer)
