//src/app/home/actions.js
import axios from 'axios'
import { END_POINT } from '../../constant'
import { setNotification } from '../../redux/notification/actions'

export const SAVED_INITIAL_FINISHING_LIST = 'SAVED_INITIAL_FINISHING_LIST'

export const REQUEST_FINISHINGS = 'REQUEST_FINISHINGS'
export const RECEIVE_FINISHINGS = 'RECEIVE_FINISHINGS'
export const GET_FINISHING = 'GET_FINISHING'
export const FAILED_FINISHINGS = 'FAILED_FINISHINGS'

const requestfinishings = () => {
    return {
        type: REQUEST_FINISHINGS,
    }
}

const getFinishings = () => {

    return async (dispatch, getState) => {
        // Loading start
        dispatch(requestfinishings())

        try {
            const token = getState().auth.token
            const request = await axios.get(`${END_POINT}finishings?token=${token}`);
            const data = request.data;

            dispatch(receiveFinishings(data))
            dispatch(saveFinishings(data))
        } catch (err) {
            dispatch(setNotification(err.error, "error"))
            dispatch(failedFinishings())
        }
    }
}


const receiveFinishings = finishings => ({
    type: RECEIVE_FINISHINGS,
    finishings
})

const saveFinishings = finishings => ({
    type: SAVED_INITIAL_FINISHING_LIST,
    finishings
})

const failedFinishings = () => ({
    type: FAILED_FINISHINGS,
})

const fnDefault = {
    getFinishings,
    receiveFinishings,
    saveFinishings
}

export default fnDefault;
