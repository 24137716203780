//src/app/home/CollectionContainer.js
import { connect } from 'react-redux'
import { openFilter, getFilterState, applyFilter, resetResult, sortBy } from './actions'
import { addAllToCart, emptyCart } from '../cart/actions'

import FilterComponent from './FilterComponent'


const mapStateToProps = state => {
    return {
        open: state.filter.open,
        filters: state.filter.filters,
        selected: state.filter.selected,
        collections: state.collections.list
    }
}

const mapDispatchToProps = dispatch => {
    const onOpenFilter = (bool) => dispatch(openFilter(bool));
    const onGetFilterState = (fieldName, value) => dispatch(getFilterState(fieldName, value))
    const onApplyFilter = () => dispatch(applyFilter())
    const onRefresh = () => dispatch(resetResult())
    const onSortBy = (direction) => dispatch(sortBy(direction))

    const onAddAllToCart = (selections, list) => dispatch(addAllToCart(selections, list));
    const onEmptyCart = () => dispatch(emptyCart())

    return {
        onOpenFilter,
        onGetFilterState,
        onApplyFilter,
        onRefresh,
        onSortBy,
        onAddAllToCart,
        onEmptyCart
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterComponent)
