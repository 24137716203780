//src/redux/localStorage.js

export const loadState = () => {

    try {
        const serializedState = localStorage.getItem('woodnet-state')
        if (serializedState === null) {
            return undefined
        }
        return JSON.parse(serializedState)
    } catch (err) {

        return undefined
    }
}

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state)
        localStorage.setItem('woodnet-state', serializedState)
    } catch (err) {
        console.log("SSS", err)
    }
}