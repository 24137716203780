//src/redux/library/libraryReducer.js

import baseReducer from './baseReducer'


class BaseState {
    array = [];
    payload = null;
    isFetching = false;
    receivedAt = null;
}


class InitialState {
    company_theme = new BaseState();
}


const fnDefault = (state = new InitialState(), action) => {

    switch (action.subtype) {
        case 'COMPANY_THEME':
            return {
                ...state,
                company_theme: baseReducer(state.company_theme, action)
            }
        default: return state;
    }
}

export default fnDefault;
