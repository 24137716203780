//src/App.js
import React, { Component } from 'react';
import { connect } from 'react-redux'
import Routes from './routes'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import './App.css'


/**
 *
 *
 *
 */
class App extends Component {

  render() {
    const { themes } = this.props

    const theme = createTheme({
      palette: {
        primary: themes ? themes.primary : { main: "#aa363b" },
        secondary: themes ? themes.secondary : { main: "#fff" },
      },
      typography: {
        htmlFontSize: 17,
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: 400,
        h1: {
          fontSize: '2.5rem',
          fontWeight: 400
        },
        h2: {
          fontSize: 36
        },
        h3: {
          fontSize: "2rem"
        },
        h6: {
          fontWeight: 600
        },
        body1: {
          fontWeight: 600,
          fontSize: 18
        },
        body2: {
          fontSize: 15
        }
      }
    })

    return (
      <MuiThemeProvider theme={theme}>
        <Routes />
      </MuiThemeProvider>
    )
  }
}

const mapStateToProps = state => {
  return {
    themes: state.library.company_theme.payload,
  }
}

export default connect(mapStateToProps)(App);
