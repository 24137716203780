//src/app/pages/home.js
import React from 'react'
import { connect } from 'react-redux'
import { Link } from "react-router-dom"
import { Typography, Grid, withStyles } from '@material-ui/core'
import { setNotification } from '../../redux/notification/actions'
import { setDownloaded } from '../layout/actions'
import Video from '../common/video'

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    container: {
        overflow: "hidden",
        width: "100%",
        textAlign: "center"

    },
    name: {
        fontSize: 20,
        marginTop: 10,
        textAlign: "left !important"
    },
    img: {
        maxWidth: "100%",
        maxHeight: "100%",
        width: "100%"
    }
})



class Home extends React.Component {

    render() {

        const { staticData, token, classes, online, isFetching } = this.props
        console.log('staticData.data', staticData.data);

        if (this.props.isFetching) {
            return <p style={{ marginTop: "30vh", marginBottom: "30vh", textAlign: "center" }}>Loading....</p>
        }

        return <div className={classes.root} style={{ marginTop: this.props.staticData && this.props.staticData.video ? 104 : 50 }}>
            {
                this.props.staticData && this.props.staticData.video ?
                    <Video video={this.props.staticData.video} videoOgv={this.props.staticData.video} token={token} />
                    : online ? <div><br /> <br /> <br /></div> : null
            }

            <div style={{ margin: 48 }}>

                <Grid container spacing={5} justify="center">
                    <Grid item xs={12} sm={6} md={6} style={{ padding: 20 }}>
                        <Link to={{ pathname: staticData.data.signature.uri, state: staticData.data.signature.params }} className={classes.container}>
                            <img src={`${staticData.data.signature.img}`} className={classes.img} alt="signature" />
                        </Link>
                        <Typography variant="caption" align="left" className={classes.name}>{staticData.data.signature.name}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} style={{ padding: 20 }}>
                        <Link to={{ pathname: staticData.data.essential.uri, state: staticData.data.essential.params }} className={classes.container}>
                            <img src={`${staticData.data.essential.img}`} className={classes.img} alt="essential" />
                        </Link>
                        <Typography variant="caption" align="left" className={classes.name}>{staticData.data.essential.name}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} style={{ padding: 20 }}>
                        <Link to={{ pathname: staticData.data.bathroom.uri, state: staticData.data.bathroom.params }} className={classes.container}>
                            <img src={`${staticData.data.bathroom.img}`} className={classes.img} alt="bathroom" />
                        </Link>
                        <Typography variant="caption" align="left" className={classes.name}>{staticData.data.bathroom.name}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} style={{ padding: 20 }}>
                        <Link to={{ pathname: staticData.data.product.uri, state: staticData.data.product.params }} className={classes.container}>
                            <img src={`${staticData.data.product.img}`} className={classes.img} alt="product" />
                        </Link>
                        <Typography variant="caption" align="left" className={classes.name}>{staticData.data.product.name}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} style={{ padding: 20 }}>
                        <Link to={{ pathname: staticData.data.finishing.uri, state: staticData.data.finishing.params }} className={classes.container}>
                            <img src={`${staticData.data.finishing.img}`} className={classes.img} alt="finishing" />
                        </Link>
                        <Typography variant="caption" align="left" className={classes.name}>{staticData.data.finishing.name}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} style={{ padding: 20 }}>
                        <Link to={{ pathname: staticData.data.projects.uri, state: null }} className={classes.container}>
                            <img src={`${staticData.data.projects.img}`} className={classes.img} alt="projects" />
                        </Link>
                        <Typography variant="caption" align="left" className={classes.name}>{staticData.data.projects.name}</Typography>
                    </Grid>

                </Grid>
            </div>


        </div>
    }
}

const mapStateToProps = state => {

    return {
        staticData: state.library.company_theme.payload.pages.home,
        token: state.auth.token,
        user: state.auth.user,
        online: state.layout.online,
        downloaded: state.layout.downloaded,
        isFetching: state.collections.isFetching,
    }
}

const StyledHome = withStyles(styles)(Home)

export default connect(mapStateToProps, { setNotification, setDownloaded })(StyledHome);
