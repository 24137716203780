import React from 'react'
import { Link } from 'react-router-dom'


const ImageCaption = (props) => {

  let item = null;
  if(props.item){
    item = props.item.details || null;
  }
  if(props.product){
    item = props.product || null;
  }

  let linkTo = props.linkTo || null;

  if(!item){ return <div></div> }

  return(
      <div>
        <p>Code: {item.code}</p>
        {item.width && item.height && item.depth ?
          <p>Dimensions: {item.width+" x "+item.depth+" x "+item.height+"cm"}</p>
          : <p> </p>
        }
        {linkTo && <Link to={linkTo}>Access to collection</Link>}
      </div>
  );

}

export default ImageCaption;
