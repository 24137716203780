//src/app/common/header.js

import React from 'react'
import { Typography, makeStyles } from '@material-ui/core'


const useStyles = makeStyles(theme => ({
    wrapper: {
        backgroundSize: "cover",
        backgroundPosition: "center",
        paddingTop: "35vh",
        paddingBottom: "35vh",
        width: '100%',
        // height: 200,

    }
}))


const Header = (props) => {
    const classes = useStyles();

    return <div className={classes.wrapper} style={{ backgroundImage: `url(${props.img})`, marginTop: props.online ? 99 : 0 }}>
        <Typography style={{ color: "white", fontSize: 60 }} variant="h1" align="center" dangerouslySetInnerHTML={{ __html: props.text }} />
    </div>
}



export default Header
