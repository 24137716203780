import { connect } from 'react-redux'
import { openFilter, getFilterState, applyFilter, resetResult, sortBy } from './actions'
import FilterComponent from './FilterComponent'

const mapStateToProps = state => {
    return {
        open: state.filter.open,
        filters: state.filter.filters,
        selected: state.filter.selected
    }
}

const mapDispatchToProps = dispatch => {
    const onOpenFilter = (bool) => dispatch(openFilter(bool));
    const onGetFilterState = (fieldName, value) => dispatch(getFilterState(fieldName, value))
    const onApplyFilter = () => dispatch(applyFilter())
    const onRefresh = () => dispatch(resetResult())
    const onSortBy = (direction) => dispatch(sortBy(direction))

    return {
        onOpenFilter,
        onGetFilterState,
        onApplyFilter,
        onRefresh,
        onSortBy
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterComponent)
