//src/app/layout/index.js

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { openDrawer, openPopover, setOffline } from './actions'
import { requestLogout } from '../auth/actions'
import { setNotification } from '../../redux/notification/actions'
import Footer from '../common/footer'
import AppBar from './AppBar'
import Drawer from './Drawer'
import { sendAll } from '../../services/sendQueued'


class Layout extends Component {

    state = {

    }

    componentDidMount() {
        this.handleConnectionChange();
        window.addEventListener('online', this.handleConnectionChange);
        window.addEventListener('offline', this.handleConnectionChange);
    }

    componentWillUnmount() {
        window.removeEventListener('online', this.handleConnectionChange);
        window.removeEventListener('offline', this.handleConnectionChange);
    }

    handleConnectionChange = () => {
        this.setState({
            condition: navigator.onLine ? 'online' : 'offline'
        })
        if (navigator.onLine) {
            sendAll()
        }

        this.props.setOffline(navigator.onLine)
    }


    render() {

        const { open, openDrawer, theme, requestLogout, selections, isClient, openPopover, openPop, anchorEl } = this.props


        if (!theme) {
            return <p style={{ marginTop: "10%", textAlign: "center" }}>Loading...</p>
        }

        return (
            <div >
                <AppBar
                    onHandleDrawerOpen={openDrawer}
                    theme={theme}
                    onLogout={requestLogout}
                    selections={selections}
                    handleOpenPopover={openPopover}
                    openPop={openPop}
                    anchorEl={anchorEl}
                    condition={this.state.condition}
                    isClient={isClient}
                />
                <Drawer
                    open={open}
                    onHandleDrawerOpen={openDrawer}
                    theme={theme}
                    onLogout={requestLogout}
                    isClient={isClient}

                />
                <main>
                    {this.props.children}
                </main>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        open: state.layout.open,
        theme: state.library.company_theme.payload,
        selections: state.cart.selections.length,
        isClient: state.auth.user.is_client,
        openPop: state.layout.openPop,
        anchorEl: state.layout.anchorEl
    }
}

export default connect(mapStateToProps, { openDrawer, setNotification, requestLogout, openPopover, setOffline })(Layout);