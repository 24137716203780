//src/app/pages/home.js
import React from 'react'
import { withStyles } from '@material-ui/core'
//import { Player } from 'video-react';

const styles = theme => ({
    root: {
        backgroundColor: "black",
        backgroundSize: "cover",
        margin: "0 auto",
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "auto",
        maxHeight: "600px"
    }

})


class Video extends React.Component {

    render() {
        const {classes, video, poster} = this.props;

        return <div className={classes.root}>

            <video
                style={{witdh: "auto", maxWidth: "100%", maxHeight: "600px"}}
                preload="auto"
                poster={poster ? poster : null}
                src={video}
                controls
            />

        </div >

    }

}

export default withStyles(styles)(Video);
